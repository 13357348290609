/*
 * @Author: 徐士杰 shijie10086@163.com
 * @Date: 2023-08-18 21:41:02
 * @LastEditors: xushijie xushijie@yunlizhihui.com
 * @LastEditTime: 2023-08-24 16:31:01
 * @FilePath: \station-web\src\main.js
 * @Description: 描述一下
 * 
 */
import { createApp } from 'vue'
import store from '@/store'
import router from '@/router'
import App from './App.vue'
// import Install from './utils/install'
import Antd from 'ant-design-vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
// 全局注册`@iconify/vue`图标库
import {
    IconifyIconOffline,
    IconifyIconOnline,
    FontIcon
} from "./components/ReIcon";
import './permission'
// import 'ant-design-vue/dist/antd.less';
import './assets/styles/tailwind.css'
import './assets/styles/index.less'
import 'ant-design-vue/dist/antd.variable.min.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'



let app = createApp(App);



app.component("IconifyIconOffline", IconifyIconOffline);
app.component("IconifyIconOnline", IconifyIconOnline);
app.component("FontIcon", FontIcon);
// Install(app)
app.use(PerfectScrollbar)
app.use(Antd)
app.use(store)
app.use(router)
app.mount('#app')
