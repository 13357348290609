/*
 * @Author: 徐士杰 xushijie@yunlizhihui.com
 * @Date: 2023-08-30 21:26:36
 * @LastEditors: 徐士杰 xushijie@yunlizhihui.com
 * @LastEditTime: 2024-06-29 15:05:37
 * @FilePath: /station-web/src/utils/theme.js
 * @Description: 描述一下
 * 
 */
export const setTheme = (data) => {
    const head = document.getElementsByTagName('head');
    const meta = document.createElement('meta');
    document.title = data.title;
    document.querySelector("meta[name='pageTitle']").setAttribute('content', data.title);
    document.querySelector("meta[name='keywords']").setAttribute('content', data.keywords);
    document.querySelector("meta[name='description']").setAttribute('content', data.description);
    head[0].appendChild(meta)

    const faviconEl = document.querySelector('link[rel="icon"]');
    if (faviconEl) {
        faviconEl.href = process.env.VUE_APP_BASE_IMAGE_URL + data.icon;
    } else {
        const newFaviconEl = document.createElement('link');
        newFaviconEl.rel = 'icon';
        newFaviconEl.href = process.env.VUE_APP_BASE_IMAGE_URL + data.icon;
        document.head.appendChild(newFaviconEl);
    }
    document.documentElement.style.setProperty('--primary-color', data.primaryColor);
    document.documentElement.style.setProperty('--link-color', data.linkColor);
    document.documentElement.style.setProperty('--border-radius-base', '2px');
}