/*
 * @Author: xushijie xushijie@yunlizhihui.com
 * @Date: 2023-03-06 08:44:01
 * @LastEditors: xsj shijie10086@163.com
 * @LastEditTime: 2024-06-17 17:32:58
 * @FilePath: /station-web/src/utils/axios.js
 * @Description: 描述一下
 * 
 */
import axios from "axios";
import store from '../store'
import router from '../router'
import { message } from 'ant-design-vue';
var instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: process.env.NODE_ENV === 'development' ? '/site-api/' : process.env.VUE_APP_BASE_API,
    timeout: 300000,
    headers: { 'Accept': 'application/json, text/plain, */*; charset=utf-8' }
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    let userInfo = localStorage.getItem("USER_INFO")
    let Cache = localStorage.getItem("X-Code-Cache")

    userInfo = userInfo ? JSON.parse(userInfo) : {}
    // 在发送请求之前做些什么
    config.headers['token'] = userInfo.token;
    config.headers['WEBSITE'] = 'localhost';
    if (Cache) {
        config.headers['X-Code-Cache'] = Cache;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 添加响应拦截器
instance.interceptors.response.use(function (response) {

    if (response.config.url.indexOf('code/getCode?t=') > -1) {
        return response
    }
    if (response.data.code == 5001) {
        store.commit('SET_USER_INFO', {})
        localStorage.removeItem('USER_INFO')
        router.push('/')
        return Promise.reject(response.data);
    }

    if (response.data.code == 200) {
        return response.data;
    } else {
        message.error(response.data.msg || response.data.code)
        return Promise.reject(response.data);
    }
}, function (error) {
    // 对响应错误做点什么
    // if (error.response.status === 404) {
    //     router.push('/404')
    // }

    // if (error.response.status == 401) {
    //     router.push('/401')
    // }
    return Promise.reject(error);
});

export default {
    get(url, data = {}) {
        return instance({ method: 'get', url, params: data })
    },
    getBlob(url, data = {}) {
        return instance({ method: 'get', url, params: data, responseType: 'blob' })
    },
    post(url, data = {}) {
        return instance({ method: 'post', url, data })
    }
};