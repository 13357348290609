/*
 * @Author: xushijie xushijie@yunlizhihui.com
 * @Date: 2023-03-06 08:44:01
 * @LastEditors: 徐士杰 xushijie@yunlizhihui.com
 * @LastEditTime: 2024-06-29 15:21:36
 * @FilePath: /station-web/src/store/modules/app.js
 * @Description: 描述一下
 * 
 */
import { NavList, getSetting, switchList, thirdLogin } from '@/api/api'
// import {MenusDispose} from '@/utils/utils'
// AdminUserInfo

const RouteSwitch = (items, father = {}) => {
    return items.map(item => {
        if (item.children) {
            item.children = RouteSwitch(item.children, item)
        }
        let path;
        if (item.type === 'nav') { // 导航
            path = `/list/${father.id ? father.id : item.id}?id=${item.id}`;
        } else if (item.type === 'article') {  // 文章列表跳转
            path = `/list/${father.id ? father.id : item.id}?id=${item.id}`;
        } else if (item.type === 'link') { // 外链
            path = `${item.url}`
        } else if (item.type === "page") { // 单篇文章跳转详情
            path = `/details/${item.id}?id=${item.articleId}`;
        } else if (item.type === 'func') { // 本地自定义功能路由页面
            path = item.routes
        } else {
            path = item.id + ''
        }

        return {
            path,
            title: item.name,
            ...item
        }
    })
}

const app = {
    state: {
        menusItems: [],
        breadcrumbItems: [],
        webConfig: {},
        loginStatus: false,
        switchItems: []
    },
    getters: {
        GET_MENUS(state) {
            return state.menusItems
        },
        GET_MENUS_FILTER(state) {
            let [item] = state.switchItems.filter(item => item.switch_code == 'fast');
            if (item && item.switch_value == 'no') {
                return state.menusItems.filter(item => {
                    if (item.children && item.children.length) {
                        item.children = item.children.filter(cdr => cdr.path != '/form/fastAuthentication')
                    }
                    return item.path != '/form/fastAuthentication'
                })
            }
            return state.menusItems
        },

        GET_SWITCH(state) {
            return state.switchItems
        },
        GET_BREADCRUMB_ITEMS(state) {
            return state.breadcrumbItems
        },
        GET_WEB_CONFIG(state) {
            return state.webConfig
        },
        GET_LOGIN_STATUS(state) {
            return state.loginStatus
        },
    },
    mutations: {
        SET_MENUS(state, menus) {
            state.menusItems = menus;
        },
        SET_BREADCRUMB_ITEMS(state, data) {
            state.breadcrumbItems = data;
        },
        SET_WEBCONFIG(state, data) {
            state.webConfig = data;
        },
        SET_LOGIN_STATUS(state, value) {
            state.loginStatus = value;
        },
        SET_SWITCH(state, data) {
            state.switchItems = data;
        }
    },
    actions: {
        LoginStatus({ commit }, value) {
            commit('SET_LOGIN_STATUS', value);
        },
        // 获取菜单
        GetMenusItems({ commit }) {
            return new Promise((resolve, reject) => {
                NavList().then(({ data }) => {
                    // console.log(data, 'NavList');
                    let menuItems = data.length ? RouteSwitch(data) : [];
                    // console.log(menuItems, 'menuItems');
                    if (data.length > 0) {
                        commit('SET_MENUS', menuItems);
                        // menuItems = MenusDispose(menuItems)
                        resolve(menuItems)
                    } else {
                        commit('SET_MENUS', menuItems);
                        resolve(menuItems)
                        throw new Error('没有菜单访问权限')
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },

        GetWebConfig({ commit }) {
            return new Promise((resolve, reject) => {
                getSetting().then(rs => {
                    const { data } = rs;
                    commit('SET_WEBCONFIG', data);
                    resolve(data)
                }).catch((err) => {
                    reject(err)
                })
            })
        },

        GetSwitch({ commit }, params = {}) {
            return new Promise((resolve, reject) => {
                switchList(params).then(res => {
                    const { data } = res;
                    commit('SET_SWITCH', data);
                    resolve(data)
                }).catch(err => reject(err))
            })
        },

        ThirdPartyLogin({ commit }, params = {}) {
            return new Promise((resolve, reject) => {
                thirdLogin({ ...params }).then(res => {
                    if (res.status) {
                        commit('SET_USER_INFO', res.data)
                        commit('SET_STATUS', res.data)
                        localStorage.setItem("USER_INFO", JSON.stringify(res.data))
                        resolve(res)
                    } else {
                        reject(res)
                    }
                }).catch(err => reject(err))
            })
        }
    },
}


export default app;