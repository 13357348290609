/*
 * @Author: xushijie xushijie@yunlizhihui.com
 * @Date: 2023-03-06 08:44:01
 * @LastEditors: 徐士杰 xushijie@yunlizhihui.com
 * @LastEditTime: 2024-06-29 15:28:01
 * @FilePath: /station-web/src/permission.js
 * @Description: 描述一下
 * 
 */
import router from './router'
import routerDefault from './router/routes'

import store from './store'
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' // 进度条样式


// 动态生成路由
const routerPackag = (routers, name = 'BasicLayout', template) => {
    console.info(`当前模板编码：  ${template}`);
    routers.filter(itemRouter => {
        if (itemRouter.type === 'func') {
            router.addRoute(name, {
                path: `${itemRouter.routes}`,
                name: itemRouter.routes.replace('/', ''),
                component: () => import(`@/views/${template}${itemRouter.routes}`),
                meta: { ...itemRouter },
            });
        }
        if (itemRouter.children && itemRouter.children.length) {
            routerPackag(itemRouter.children, name, template);
        }
        return true
    })
}

// 动态添加本地静态路由模板
const dynamicAddLocalRoute = (routers, name = "PageLayout") => {
    routers.filter(itemRouter => {
        router.addRoute(name, itemRouter);
        if (itemRouter.children && itemRouter.children.length) {
            dynamicAddLocalRoute(itemRouter.children, name);
        }
        return true
    })
}


const tokenRoutes = ['/manage/home']

const thirdPartyLogin = async (ticket, service, next) => {
    console.log(ticket, 'www ticket');
    await store.dispatch('ThirdPartyLogin', { ticket, service });
    next()
}



// 路由拦截
router.beforeEach((to, from, next) => {
    NProgress.start()
    // 用户状态
    let userInfo = localStorage.getItem("USER_INFO")
    userInfo = userInfo ? JSON.parse(userInfo) : {}

    if (store.getters.GET_MENUS.length < 1) {
        store.dispatch('GetWebConfig').then(() => {
            store.dispatch('GetSwitch');
            if (userInfo.token) {
                store.dispatch('GetStatus');
            }

            if (tokenRoutes.includes(to.path) && !userInfo.token) {
                next('/')
            } else {
                // 获取路由权限
                store.dispatch('GetMenusItems').then(res => {
                    // console.log(res, '0');
                    let routes = routerDefault(store.getters.GET_WEB_CONFIG.templateCode ?? 'default');
                    // console.log(routes, '1');
                    // console.log(router.getRoutes(), '2');
                    // console.log(store.getters.GET_WEB_CONFIG, 'GET_WEB_CONFIG');
                    dynamicAddLocalRoute(routes, 'PageLayout')
                    // console.log(router.getRoutes(), '3');
                    routerPackag(res, 'PageLayout', store.getters.GET_WEB_CONFIG.templateCode ?? 'default')
                    next(to.fullPath)
                })
            }
        })
    } else {
        if (store.getters.GET_LOGIN_STATUS) {
            next()
        } else {
            const { href, origin: winOrigin } = window.location
            const query = new URL(href).searchParams
            const hasT = query.has('ticket')
            const hasO = query.has('origin')
            const origin = query.get('origin')
            const ticket = query.get('ticket')
            if (hasO && hasT) thirdPartyLogin(ticket, `${winOrigin}/?origin=${origin}`, next)
            if (hasO && !hasT) window.location.href = `${process.env.VUE_APP_THIRD_PATRTY_LOGIN_ADDRESS}?service=${winOrigin}/?origin=${origin}`
            if (!hasO) next()
        }
    }
})

// to, from
router.afterEach(() => {
    NProgress.done()
})
