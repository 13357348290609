import axios from "@/utils/axios";

export const GetTheme = (data) => {
    return axios.post('theme', data)
}

export const UserLogin = (data) => {
    return axios.post('user/login', data)
}
export const GetCodeUrl = 'code/getCode'

export const GetCodeImg = (data) => {
    return axios.getBlob(`${GetCodeUrl}?t=${new Date().getTime()}`, data)
}

export const GetStatus = (data) => {
    return axios.get('user/status', data)
}

export const SMSPhone = (data) => {
    return axios.post(`sms/phone `, data)
}
export const GetIndex = (data) => {
    return axios.get('front/index', data)
}
export const BannerList = (data) => {
    return axios.get('front/banner', data)
}
export const NavList = (data) => {
    return axios.get('front/nav/treelist', data)
}
export const Details = (data, id) => {
    return axios.get(`front/article/${id}`, data)
}

export const ArticleList = (data, id) => {
    return axios.get(`front/article/list/${id}`, data)
}

export const IndexLeft = (data) => {
    return axios.get(`front/content`, data)
}


export const UserReg = (data) => {
    return axios.post(`user/reg`, data)
}

export const UserEdit = (data) => {
    return axios.post(`user/edit`, data)
}

export const UserCompanyEdit = (data) => {
    return axios.post(`company/save`, data)
}

export const GetCompanyDetails = (data, id) => {
    return axios.post(`company/detail/${id}`, data)
}
export const DonateAdd = (data) => {
    return axios.post(`donate/add`, data)
}

export const SearchArticleList = (data) => {
    return axios.get(`index/search`, data)
}

export const VisitAdd = (data) => {
    return axios.post(`visit/add`, data)
}
export const SuggestAdd = (data) => {
    return axios.post(`suggest/add`, data)
}

export const RecruitAdd = (data) => {
    return axios.post(`recruit/add`, data)
}
export const RecruitGetByUser = (data) => {
    return axios.get(`user/recruit`, data)
}
// `recruit/list`

export const RecruitList = (data) => {
    return axios.get(`recruit/list`, data)
}

export const RecruitDetails = (data, id) => {
    return axios.get(`recruit/detail/${id}`, data)
}

export const DonateList = (data) => {
    return axios.get(`donate/list`, data)
}
export const BranchList = (data) => {
    return axios.get(`branch/list`, data)
}
export const BranchTreeList = (data) => {
    return axios.get(`branch/listFst`, data)
}
export const BranchTreeCdrList = (data, id) => {
    return axios.get(`branch/listChild/${id}`, data)
}
// 重置密码
export const UserReset = (data) => {
    return axios.post(`user/reset`, data)
}
// 我的捐赠
export const donateByUser = (data) => {
    return axios.get(`user/donate`, data)
}
// 我的活动
export const activityByUser = (data) => {
    return axios.get(`user/activity`, data)
}

export const ActivityList = (data) => {
    return axios.get(`activity/list`, data)
}
export const ActivityDetails = (data, id) => {
    return axios.get(`activity/detail/${id}`, data)
}
export const ActivityUserReg = (data, id) => {
    return axios.post(`activity/userReg/${id}`, data)
}
export const ActivityUserCancel = (data, id) => {
    return axios.post(`activity/userCancel/${id}`, data)
}
export const ActivityUserList = (data, id) => {
    return axios.get(`activity/userList/${id}`, data)
}

// 图片上传
export const uploadFile = (formData) => {
    const action = (process.env.NODE_ENV === 'development' ? `${process.env.VUE_APP_BASE_API}/adm/` : process.env.VUE_APP_BASE_API) + 'attach/upload'
    console.log(action, 'www action');
    return axios.post(action, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const updateAvatar = (data) => {
    return axios.post(`user/avatar`, data)
}

export const userForgot = (data) => {
    return axios.post(`user/forget`, data)
}

export const getSetting = () => axios.get('front/settings/detail')


export const visitAddressList = () => axios.get('visitAddress/list')

export const activityMsgList = (data, id) => axios.get(`activityMsg/actList/${id}`, data)
export const activityMsgNameList  = (data, id) => axios.get(`activityMsg/nameList/${id}`, data)
export const activityMsgAdd = (data) => axios.post(`activityMsg/add`, data)
export const visitNoticeDetail = (data) => axios.get(`visitNotice/detail`, data)

export const switchList = (data) => axios.get(`front/switch/list`, data)
export const userFastReg = (data) => axios.post(`user/fastReg`, data)
export const thirdLogin = (data) => axios.post(`auth/thirdLogin`, data)