/*
 * @Author: 徐士杰 shijie10086@163.com
 * @Date: 2023-08-18 21:40:25
 * @LastEditors: ShiJie Xu shijie10086@163.com
 * @LastEditTime: 2023-08-20 01:44:43
 * @FilePath: \station-web-test\src\router\index.js
 * @Description: 描述一下
 * 
 */
import { createRouter, createWebHistory } from "vue-router"

const router = createRouter({
    history: createWebHistory(),
    routes: []
})

export default router