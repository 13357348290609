<!--
 * @Author: xushijie xushijie@yunlizhihui.com
 * @Date: 2023-03-06 08:44:01
 * @LastEditors: 徐士杰 xushijie@yunlizhihui.com
 * @LastEditTime: 2024-06-30 08:33:40
 * @FilePath: /station-web/src/App.vue
 * @Description: 描述一下
 * 
-->
<template>
  <perfect-scrollbar>
    <a-config-provider :locale="zhCN">
      <router-view></router-view>
    </a-config-provider>
  </perfect-scrollbar>
</template>
<script setup name="App">
import "@/components/ReIcon/src/offlineIcon";
import { computed, watch, unref } from 'vue'
import { ConfigProvider } from 'ant-design-vue';
import { useStore } from 'vuex';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import { setTheme } from '@/utils/theme'
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

let store = useStore()
let webConfig = computed(() => store.getters.GET_WEB_CONFIG);
watch(
  () => webConfig,
  () => {
    const data = unref(webConfig)
    // console.log(data,'www data');
    setTheme(data)
    ConfigProvider.config({
      theme: {
        primaryColor: data.primaryColor,
        linkColor: data.linkColor,
      },
    });
  }, {
  deep: true,
})
</script>

<style lang="less" scoped>
.ps {
  :deep(.ps__rail-y) {
    z-index: 999 !important;
  }
}
</style>