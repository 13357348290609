import { addIcon } from "@iconify/vue/dist/offline";

/**
 * 这里存放本地图标，在 src/layout/index.vue 文件中加载，避免在首启动加载
 */

// 本地菜单图标，后端在路由的icon中返回对应的图标字符串并且前端在此处使用addIcon添加即可渲染菜单图标
import InformationLine from "@iconify-icons/ri/information-line";
import MailFill from "@iconify-icons/ri/mail-fill";
import Book2Fill from "@iconify-icons/ri/book-2-fill";
import InformationFill from "@iconify-icons/ri/information-fill";
import SunFill from "@iconify-icons/ri/sun-fill";
import TreeFill from "@iconify-icons/ri/truck-line";
import TimeFill from "@iconify-icons/ri/time-fill";
import StarFill from "@iconify-icons/ri/star-fill";
import BookmarkFill from "@iconify-icons/ri/bookmark-fill";
addIcon("informationLine", InformationLine);
addIcon("mailFill", MailFill);
addIcon("book2Fill", Book2Fill);
addIcon("informationFill", InformationFill);
addIcon("sunFill", SunFill);
addIcon("treeFill", TreeFill);
addIcon("timeFill", TimeFill);
addIcon("starFill", StarFill);
addIcon("bookmarkFill", BookmarkFill);
