import { UserLogin, GetStatus, GetCompanyDetails } from '@/api/api'
import { message } from 'ant-design-vue';
let userInfo = localStorage.getItem('USER_INFO') ? JSON.parse(localStorage.getItem('USER_INFO')) : {};
let company = localStorage.getItem('USER_COMPANY_INFO') ? JSON.parse(localStorage.getItem('USER_COMPANY_INFO')) : {};

const user = {
    state: {
        userInfo: typeof userInfo.id !== 'undefined' ? userInfo : {},
        status: {},
        company: typeof company.id !== 'undefined' ? company : {},
    },
    getters: {
        GET_USER_INFO(state) {
            return state.userInfo
        },
        GET_STATUS(state) {
            return state.status
        },
        GET_COMPANY(state) {
            return state.company
        }
    },
    mutations: {
        SET_USER_INFO(state, info) {
            state.userInfo = info;
        },
        SET_STATUS(state, status) {
            state.status = status
        },
        SET_COMPANY(state, company) {
            state.company = company
        }
    },
    actions: {
        // 获取菜单
        UserLogin({ commit }, params = {}) {
            return new Promise((resolve, reject) => {
                UserLogin({ ...params }).then(res => {
                    if (res.status) {
                        commit('SET_USER_INFO', res.data)
                        commit('SET_STATUS', res.data)
                        localStorage.setItem("USER_INFO", JSON.stringify(res.data))
                        resolve(res)
                    } else {
                        reject(res)
                        // message.warning(res.message);
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        GetStatus({ commit }, params = {}) {
            return new Promise((resolve, reject) => {
                GetStatus({ ...params }).then(res => {
                    if (res.status) {
                        commit('SET_STATUS', res.data)
                        resolve(res)
                    } else {
                        commit('SET_STATUS', {})
                        localStorage.removeItem('SET_STATUS')
                        message.warning(res.message);
                        reject(res)
                    }
                })
            })
        },
        GetCompany({ commit }, params = {}) {
            return new Promise((resolve, reject) => {
                GetCompanyDetails({ ...params }, params.id).then(res => {
                    if (res.status) {
                        commit('SET_COMPANY', res.data)
                        localStorage.setItem("USER_COMPANY_INFO", JSON.stringify(res.data))
                        resolve(res)
                    } else {
                        commit('SET_COMPANY', {})
                        localStorage.removeItem('USER_COMPANY_INFO')
                        message.warning(res.message);
                        reject(res)
                    }
                })
            })
        }

    },
}


export default user;