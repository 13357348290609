/*
 * @Author: 徐士杰 shijie10086@163.com
 * @Date: 2023-08-18 21:41:02
 * @LastEditors: ShiJie Xu shijie10086@163.com
 * @LastEditTime: 2023-08-20 01:33:05
 * @FilePath: \station-web-test\src\router\routes.js
 * @Description: 描述一下
 * 
 */
// 默认基本路由
const routerDefault = (template) => {
    return [
        {
            path: '/',
            name: 'PageLayout',
            component: () => import(`@/views/${template}/layout`),
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: () => import(`@/views/${template}/index`),
                },
                {
                    path: '/list/:id',
                    component: () => import(`@/views/${template}/list`),
                },
                {
                    path: '/details/:id',
                    component: () => import(`@/views/${template}/details`),
                },
                {
                    path: '/form/activity/details',
                    component: () => import(`@/views/${template}/form/activityDetails`),
                },
                {
                    path: '/articleDetails/:id',
                    component: () => import(`@/views/${template}/articleDetails`),
                },

                {
                    path: '/search',
                    component: () => import(`@/views/${template}/search`),
                },
                {
                    path: '/manage/home',
                    component: () => import(`@/views/${template}/manage/home`),
                },

            ]
        },
        {
            name: '404',
            path: '/404',
            component: () => import(`@/views/${template}/404.vue`)
        },
        {
            path: "/:pathMatch(.*)*",
            redirect: '/404',
        }
    ]
}

export default routerDefault;